import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import storage from 'framework/src/StorageProvider';
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
interface ProfessionalDetails {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      first_name: string;
      last_name: string;
      role_id: string;
      profile_status: string;
      work_experiences: WorkExperience[];
      educations: Education[];
      certificates: Certificate[];
      licenses: License[];
    };
  };
}

interface WorkExperience {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  company: string;
  job_title: string;
  location: string;
  description: string;
  start_date: string;
  end_date: string;
}

interface Education {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  degree_name: string;
  college_name: string;
  start_date: string;
  end_date: string;
  description: string;
  degree_img: {
    url: string | null;
  };
}

interface Certificate {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  certificate_name: string;
  certificate_provider: string;
  certification_id: string;
  certification_url: string;
  description: string;
  issue_date: string;
  expiration_date: string;
}

interface License {
  id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
}

export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;

}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    }
    ;
    id: number; // Add this line

  }
}

export interface Dropdown {
  label: string;
  value: string;
}
interface Form {
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    id: number;
    login: string, 
    password: string
   title:string;
   company:string
   startDate: number,
   endDate: number,
   description: string,

  }
  interface Errordata {
    id: number;
   title:any;
    company: any;
    startDate: string;
    endDate: string;
    description: string;
  }

  interface ExperienceForm {
    isCurrentlyWorking: boolean;
    id: string;
    title: string;
    company: string;
    startMonth: string;
    startYear: string;
    endMonth: string;
    endYear: string;
    description: string;
  }

  interface ExperienceErrordata {
    id: string;
  title: string;
  company: string;
  startMonth: string;
  startYear: string;
  endMonth: string;
  endYear: string;
  description: string;
  }

  interface EducationForm {
    id: string;
    degree: string;
    university: string;
    country: string;
    yearFrom: string;
    yearTo: string;
    description: string;
  }

  
  interface educationErrordata {
    id: string;
    degree: string;
    university: string;
    country: string;
    yearFrom: string;
    yearTo: string;
    description: string;
  }
  interface CertificateForm {
    id: string;
    certificate_name: string;
    certificate_provider: string;
    certificate_id: string;
    certificate_url: string;
    certificate_issue: string;
    certificate_expire_date: string;
  }
  
  interface CertificateErrors {
    certificate_name: string;
    certificate_provider: string;
    certificate_id: string;
    certificate_url: string;
    certificate_issue: string;
    certificate_expire_date: string;
  }
  interface LicenseForm {
    id: string;
    Licensed_Profession: string;
    Licensing_Body: string;
    License_Number: string;
    Licensing_Verification_URL: string;
    Issue_Date: string;
    Expiration_Date: string;
  }
  
  interface LicenseErrors {
    Licensed_Profession: string;
    Licensing_Body: string;
    License_Number: string;
    Licensing_Verification_URL: string;
    Issue_Date: string;
    Expiration_Date: string;
  }

export interface Data {
    id: number; // Add this line
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  forms: Form[];
  errors:Errordata[];
  experienceForm: ExperienceForm[];
  experienceErrordata: ExperienceErrordata[];
  educationForms: EducationForm[];
  educationerrors: educationErrordata[];
  certificateForms: CertificateForm[];
  certificateerrors: CertificateErrors[];
  licenseForms: LicenseForm[];
  licenseErrors: LicenseErrors[];
  professionalDetails : ProfessionalDetails[];
  loading: boolean;
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  authToken:string
  userID:number |null;
  backdata:boolean;
do_It_Later:boolean;

  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  apiExperienceID:string ="";
  professional_detailsBack_APIid="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
    do_It_Later:false,
      professionalDetails: [],
      forms: [{
          id: 1,
          title: '',
          company: '',
          login: "",
          password: "",
          description: "",
          startDate: 0,
          endDate: 0,
          startMonth: "",
          startYear: "",
          endMonth: "",
          endYear: ""
      }],
  errors: [{
      id: 1, title: false,
       company: false,
      startDate: '',
      endDate: '',
      description: ''
  }],
  experienceForm: [{ 
    id: '',
    title: '',
    company: '',
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    description: '',
    isCurrentlyWorking:false
   }],
  experienceErrordata: [],

  educationForms: [{ id: '1', degree: '', university: '', country: '', yearFrom: '', yearTo: '', description: '' }],
  educationerrors: [],

  certificateForms: [
    { id: '1', certificate_name: '', certificate_provider: '', certificate_id: '', certificate_url: '', certificate_issue: '', certificate_expire_date: '' },
  ],
  certificateerrors: [
    { certificate_name: '', certificate_provider: '', certificate_id: '', certificate_url: '', certificate_issue: '', certificate_expire_date: '' },
  ],

  licenseForms: [
    {
      id: '1',
      Licensed_Profession: '',
      Licensing_Body: '',
      License_Number: '',
      Licensing_Verification_URL: '',
      Issue_Date: '',
      Expiration_Date: '',
    },
  ],
  licenseErrors: [
    {
      Licensed_Profession: '',
      Licensing_Body: '',
      License_Number: '',
      Licensing_Verification_URL: '',
      Issue_Date: '',
      Expiration_Date: '',
    },
  ],
      loading: false,
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      authToken:"",
      userID:null,
      backdata:false,

      // Customizable Area End
    };

    // Customizable Area Start
    this.handleChange = this.handleChange.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      if (sessionData) {
        this.setState({backdata:sessionData.backdata})
        this.getprofessionalDetailsBack()
      }
      return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
       
        if (apiRequestCallId === this.apiExperienceID) {
          this.setState({ professionalDetails: responseJson });
        }
        if (apiRequestCallId === this.professional_detailsBack_APIid) {
         this.setFormData(responseJson)
        }

        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getUserList();
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
    });
  }

  onValueChange = (name: string, value: string) => {
    this.setState({ ...this.state, [name]: value });
  };

  onAddBtnClicked = () => {
    this.setState({ openDialogName: "Add" });
  }

  onFileChange = () => {
   
  }

  onChangeHandler = (page: number) => {
    let { rowsPerPage, filterData } = this.state;
    let data = filterData;
    const dataLength = data.length;
    let totalPage = Math.ceil(dataLength / this.state.rowsPerPage);
    page = totalPage === page ? page - 1 : page;
    data = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    this.setState({ data, dataLength, page, loading: false });
  };

  onEditBtnClicked = (item: Data) => {
    this.setState({
      userId: item.userId,
      firstName: item.firstName,
      lastName: item.lastName,
      phoneNumber: item.phoneNumber,
      email: item.email,
      organization: item.organization,
      teamName: item.teamName,
      userType: item.userType,
      rating: item.rating,
      gender: item.gender,
      address: item.address,
      country: item.country,
      state: item.state,
      city: item.city,
      file: item.file,
      openDialogName: "Edit"
    });
  }

  onCancel = () => {
    this.setState({
      openDialogName: "",
      userId: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: ""
    });
  }

  getUserList = () => {
    this.setState({ loading: true });

    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: localStorage.getItem("accessToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserListApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onAddTask = () => {
    this.setState({ loading: true });

    const header = {
      "token": localStorage.getItem("accessToken")
    };

    const formData = new FormData();
    formData.append("form[first_name]", this.state.firstName);
    formData.append("form[last_name]", this.state.lastName);
    formData.append("form[phone_number]", this.state.phoneNumber);
    formData.append("form[organization]", this.state.organization);
    formData.append("form[team_name]", this.state.teamName);
    formData.append("form[i_am]", this.state.userType);
    formData.append("form[gender]", this.state.gender);
    formData.append("form[stars_rating]", this.state.rating);
    formData.append("form[email]", this.state.email);
    formData.append("form[address]", this.state.address);
    formData.append("form[country]", this.state.country);
    formData.append("form[state]", this.state.state);
    formData.append("form[city]", this.state.city);
    formData.append("form[file]", this.state.file as Blob);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.addUserApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

 



  
handleAddForm = () => {
    const newForm: Form = {
        id: this.state.forms.length + 1, login: '', password: '',
        title: "",
        company: "",
        startDate:0,
        endDate: 0,
        description: '',
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: ""
    };
    this.setState((prevState) => ({
      forms: [...prevState.forms, newForm]
    }));
  };


  handleRemoveForm = (id: number) => {
    this.setState((prevState) => ({
      forms: prevState.forms.filter((form) => form.id !== id)
    }));
  };

  handleChange(e: React.ChangeEvent<HTMLInputElement>, id: number, field: string) {
    const { value } = e.target;
    const parsedValue = field === 'year' ? parseInt(value, 10) : value; 
  
    const updatedForms = this.state.forms.map(form => 
      form.id === id ? { ...form, [field]: parsedValue } : form
    );
  
    const updatedErrors = this.state.errors.map((error:any, index) => {
      if (this.state.forms[index].id === id) {
        return {
          ...error,
          [field]: parsedValue ? "" : error[field], 
        };
      }
      return error;
    });
  
    this.setState({ forms: updatedForms, errors: updatedErrors });
  }

   validateForms() {
    let isValid = true;
    const errors: Errordata[] = this.state.forms.map(() => ({
      title: "",
      company: "",
      startDate: "",
      endDate: "",
      description: "",
      id:0
    }));
  
    this.state.forms.forEach((form, index) => {
      const formErrors = errors[index];
  
      if (!form.title) {
        isValid = false;
        formErrors.title = 'Title is required';
      }
  
      if (!form.company) {
        isValid = false;
        formErrors.company = 'Company is required';
      }
  
      if (!form.startMonth || !form.startYear) {
        isValid = false;
        formErrors.startDate = 'Start date is required';
      }
  
      if (!form.endMonth || !form.endYear) {
        isValid = false;
        formErrors.endDate = 'End date is required';
      }
    });
  
    this.setState({ errors });
    return isValid;
  }
  handleNext() {

    const educationIsValid = this.validateEducationForms();
    const certificateIsValid = this.validatecertificateForms();
    const licenseIsValid = this.validateLicenseForms();
    const experienceIsValid = this.validateExperienceForms();
  
    if ( educationIsValid && certificateIsValid && licenseIsValid && experienceIsValid) {
     
  
      this.handleProfesstionAndExperienceApi(); 

       this.props.navigation.navigate( 'ExpertiseAndFeesForm');

    } else {
      console.log('Validation failed! Please fix the errors.');
    }
  }

  handlebackpage(){    
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CustomForm'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {backToCustom: true })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  

  handleEducationChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
    id: string,
    field: string
  ) => {
    const { value } = e.target;

    const updatedEducationForms = this.state.educationForms.map(form =>
      form.id === id ? { ...form, [field]: value } : form
    );

    const updatedErrors = this.state.educationerrors.map((error: any, index) => {
      const form = this.state.educationForms[index];
      if (form && form.id === id) {
        return {
          ...error,
          [field]: value ? "" : `The ${field} field is required`,
        };
      }
      return error; 
    });
    

    this.setState({ educationForms: updatedEducationForms, educationerrors: updatedErrors });
  };

  handleAddEducationForm = () => {
    this.setState(prevState => ({
      educationForms: [
        ...prevState.educationForms,
        { id: (prevState.educationForms.length + 1).toString(), degree: '', university: '', country: '', yearFrom: '', yearTo: '', description: '' }
      ],
      educationerrors: [
        ...prevState.educationerrors,
        { id: (prevState.educationForms.length + 1).toString(), degree: '', university: '', country: '', yearFrom: '', yearTo: '', description: '' }
      ]
    }));
    this.handleProfesstionAndExperienceApi()
  };

  handleRemoveEducationForm = (id: string) => {
    this.setState(prevState => ({
      educationForms: prevState.educationForms.filter(form => form.id !== id),
      educationerrors: prevState.educationerrors.filter(error => error.id !== id),
    }));
  };

  validateEducationForms = () => {
    let isValid = true;
    const errors = this.state.educationForms.map(() => ({
      degree: "",
      university: "",
      country: "",
      yearFrom: "",
      yearTo: "",
      description: "",
      id: ''
    }));

    this.state.educationForms.forEach((form, index) => {
      const formErrors = errors[index];

      if (!form.degree) {
        isValid = false;
        formErrors.degree = 'Degree is required';
      }

      if (!form.university) {
        isValid = false;
        formErrors.university = 'University name is required';
      }

      if (!form.country) {
        isValid = false;
        formErrors.country = 'Country is required';
      }

      if (!form.yearFrom) {
        isValid = false;
        formErrors.yearFrom = 'Year attended (From) is required';
      }

      if (!form.yearTo) {
        isValid = false;
        formErrors.yearTo = 'Year attended (To) is required';
      }
    });

    this.setState({ educationerrors: errors });
    return isValid;
  };

handleAddcertificateForm = () => {
  this.setState(prevState => ({
    certificateForms: [
      ...prevState.certificateForms,
      { id: (prevState.certificateForms.length + 1).toString(), certificate_name: '', certificate_provider: '', certificate_id: '', certificate_url: '', certificate_issue: '', certificate_expire_date: '' }
    ],
    certificateerrors: [
      ...prevState.certificateerrors,
      { certificate_name: '', certificate_provider: '', certificate_id: '', certificate_url: '', certificate_issue: '', certificate_expire_date: '' }
    ]
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemovecertificateForm = (id: string) => {
  this.setState(prevState => ({
    certificateForms: prevState.certificateForms.filter(form => form.id !== id),
    certificateerrors: prevState.certificateerrors.filter((_, index) => prevState.certificateForms[index].id !== id),
  }));
};

handlecertificateChange = (e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>, id: string, field: string) => {
  const { value } = e.target;

  const updatedCertificateForms = this.state.certificateForms.map(form =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.certificateerrors.map((error: any, index) => {
    if (this.state.certificateForms[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `${field} is required`,
      };
    }
    return error;
  });

  this.setState({
    certificateForms: updatedCertificateForms,
    certificateerrors: updatedErrors
  });
};

validatecertificateForms = () => {
  let isValid = true;
  const errors: CertificateErrors[] = this.state.certificateForms.map(() => ({
    certificate_name: '',
    certificate_provider: '',
    certificate_id: '',
    certificate_url: '',
    certificate_issue: '',
    certificate_expire_date: ''
  }));

  this.state.certificateForms.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.certificate_name) {
      isValid = false;
      formErrors.certificate_name = 'Certificate name is required';
    }

    if (!form.certificate_provider) {
      isValid = false;
      formErrors.certificate_provider = 'Certificate provider is required';
    }

    if (!form.certificate_issue) {
      isValid = false;
      formErrors.certificate_issue = 'Certificate issue date is required';
    }

  });

  this.setState({ certificateerrors: errors });
  return isValid;
};

handleAddLicenseForm = () => {
  this.setState(prevState => ({
    licenseForms: [
      ...prevState.licenseForms,
      {
        id: (prevState.licenseForms.length + 1).toString(),
        Licensed_Profession: '',
        Licensing_Body: '',
        License_Number: '',
        Licensing_Verification_URL: '',
        Issue_Date: '',
        Expiration_Date: '',
      },
    ],
    licenseErrors: [
      ...prevState.licenseErrors,
      {
        Licensed_Profession: '',
        Licensing_Body: '',
        License_Number: '',
        Licensing_Verification_URL: '',
        Issue_Date: '',
        Expiration_Date: '',
      },
    ],
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemoveLicenseForm = (id: string) => {
  this.setState(prevState => ({
    licenseForms: prevState.licenseForms.filter(form => form.id !== id),
    licenseErrors: prevState.licenseErrors.filter(error => error.License_Number !== id),
  }));
};

handleLicenseChange = (
  e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  id: string,
  field: string
) => {
  const { value } = e.target;
  const updatedLicenseForms = this.state.licenseForms.map(form =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.licenseErrors.map((error, index) => {
    if (this.state.licenseForms[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `${field} is required`,

      };
    }
    return error;
  });

  this.setState({ licenseForms: updatedLicenseForms, licenseErrors: updatedErrors });
};

validateLicenseForms = () => {
  let isValid = true;
  const errors: LicenseErrors[] = this.state.licenseForms.map(() => ({
    Licensed_Profession: '',
    Licensing_Body: '',
    License_Number: '',
    Licensing_Verification_URL: '',
    Issue_Date: '',
    Expiration_Date: '',
  }));

  this.state.licenseForms.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.Licensed_Profession) {
      isValid = false;
      formErrors.Licensed_Profession = 'Licensed Profession is required';
    }

    if (!form.Licensing_Body) {
      isValid = false;
      formErrors.Licensing_Body = 'Jurisdiction or Licensing Body is required';
    }

    if (!form.License_Number) {
      isValid = false;
      formErrors.License_Number = 'License Number is required';
    }

    if (!form.Issue_Date) {
      isValid = false;
      formErrors.Issue_Date = 'Issue Date is required';
    }

    if (!form.Licensing_Verification_URL) {
      isValid = false;
      formErrors.Licensing_Verification_URL = 'Licensing Verification URL is required';
    }
  });

  this.setState({ licenseErrors: errors });
  return isValid;
};




handleCheckboxChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  id: string
) => {
  const { checked } = e.target;

  const updatedExperienceForms = this.state.experienceForm.map((form) =>
    form.id === id ? { ...form, isCurrentlyWorking: checked } : form
  );

  this.setState({
    experienceForm: updatedExperienceForms,
  });
};
handleExperienceChange = (
  e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>,
  id: string,
  field: string
) => {
  const { value } = e.target;

  const updatedExperienceForms = this.state.experienceForm.map((form) =>
    form.id === id ? { ...form, [field]: value } : form
  );

  const updatedErrors = this.state.experienceErrordata.map((error: any, index) => {
    if (this.state.experienceForm[index].id === id) {
      return {
        ...error,
        [field]: value ? "" : `The ${field} field is required`,
      };
    }
    return error;
  });

  this.setState({
    experienceForm: updatedExperienceForms,
    experienceErrordata: updatedErrors,
  });
};
handleAddExperienceForm = () => {
  this.setState((prevState) => ({
    experienceForm: [
      ...prevState.experienceForm,
      {
        id: (prevState.experienceForm.length + 1).toString(),
        title: "",
        company: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
        isCurrentlyWorking:false
      },
    ],
    experienceErrordata: [
      ...prevState.experienceErrordata,
      {
        id: (prevState.experienceForm.length + 1).toString(),
        title: "",
        company: "",
        startMonth: "",
        startYear: "",
        endMonth: "",
        endYear: "",
        description: "",
      },
    ],
  }));
  this.handleProfesstionAndExperienceApi()
};

handleRemoveExperienceForm = (id: string) => {
  this.setState((prevState) => ({
    experienceForm: prevState.experienceForm.filter((form) => form.id !== id),
    experienceErrordata: prevState.experienceErrordata.filter(
      (error) => error.id !== id
    ),
  }));
};

validateExperienceForms = () => {
  let isValid = true;
  const errors = this.state.experienceForm.map(() => ({
    title: "",
    company: "",
    startMonth: "",
    startYear: "",
    endMonth: "",
    endYear: "",
    description: "",
    id: "",
  }));

  this.state.experienceForm.forEach((form, index) => {
    const formErrors = errors[index];

    if (!form.title) {
      isValid = false;
      formErrors.title = "Title is required";
    }

    if (!form.company) {
      isValid = false;
      formErrors.company = "Company name is required";
    }

    if (!form.startMonth) {
      isValid = false;
      formErrors.startMonth = "Start month is required";
    }

    if (!form.startYear) {
      isValid = false;
      formErrors.startYear = "Start year is required";
    }

    if (!form.isCurrentlyWorking) {
      if (!form.endMonth) {
        isValid = false;
        formErrors.endMonth = "End month is required";
      }

      if (!form.endYear) {
        isValid = false;
        formErrors.endYear = "End year is required";
      }
    }
  });

  this.setState({ experienceErrordata: errors });
  return isValid;
};

handleProfesstionAndExperienceApi = () => {
  const header = {
    redirect: 'follow',
    "token": this.state.authToken
  };
  
  let formdata = new FormData();
  
  // Work Experiences
  this.state.experienceForm.forEach((experience) => {

    if(this.state.backdata){
    formdata.append(`account[work_experiences_attributes][0][id]`, experience.id );
    }
    formdata.append(`account[work_experiences_attributes][0][company]`, experience.company);
    formdata.append(`account[work_experiences_attributes][0][job_title]`, experience.title);
    formdata.append(`account[work_experiences_attributes][0][start_date]`, `${experience.startYear}-${experience.startMonth}-1`);
    formdata.append(`account[work_experiences_attributes][0][end_date]`, `${experience.endYear}-${experience.endMonth}-1`);
    formdata.append(`account[work_experiences_attributes][0][description]`, experience.description);
  });


  // Education
  this.state.educationForms.forEach((education) => {
    if(this.state.backdata){
      formdata.append(`account[work_experiences_attributes][0][id]`, education.id );
      }
    formdata.append(`account[educations_attributes][0][degree_name]`, education.degree);
    formdata.append(`account[educations_attributes][0][college_name]`, education.university);
    formdata.append(`account[educations_attributes][0][start_date]`, `${education.yearFrom}-01-01`); 
    formdata.append(`account[educations_attributes][0][end_date]`, `${education.yearTo}-01-01`); 
    formdata.append(`account[educations_attributes][0][description]`, education.description);
  });

  // Certificates
  this.state.certificateForms.forEach((certificate) => {
    if(this.state.backdata){
      formdata.append(`account[work_experiences_attributes][0][id]`, certificate.id );
      }
    formdata.append(`account[certificates_attributes][0][certificate_name]`, certificate.certificate_name);
    formdata.append(`account[certificates_attributes][0][certificate_provider]`, certificate.certificate_provider);
    formdata.append(`account[certificates_attributes][0][certification_id]`, certificate.certificate_id);
    formdata.append(`account[certificates_attributes][0][certification_url]`, certificate.certificate_url);
    formdata.append(`account[certificates_attributes][0][issue_date]`, certificate.certificate_issue);
    formdata.append(`account[certificates_attributes][0][expiration_date]`, certificate.certificate_expire_date);
  });

  // Licenses
  this.state.licenseForms.forEach((license) => {
    if(this.state.backdata){
      formdata.append(`account[work_experiences_attributes][0][id]`, license.id );
      }
    formdata.append(`account[licenses_attributes][0][licensed_profession]`, license.Licensed_Profession);
    formdata.append(`account[licenses_attributes][0][jurisdiction_or_licensing_body]`, license.Licensing_Body);
    formdata.append(`account[licenses_attributes][0][license_number]`, license.License_Number);
    formdata.append(`account[licenses_attributes][0][licensing_verification_url]`, license.Licensing_Verification_URL);
    formdata.append(`account[licenses_attributes][0][issue_date]`, license.Issue_Date);
    formdata.append(`account[licenses_attributes][0][expiry_date]`, license.Expiration_Date);
  });

  // Send the request
  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.apiExperienceID = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/freelancers/${this.state.userID}/freelancer_profile_professional_details`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    formdata
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "PATCH"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  
  return true;
};

getprofessionalDetailsBack = () => {
  const header = {
    "Content-Type": "application/json",
    "token": this.state.authToken
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.professional_detailsBack_APIid = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `account_block/freelancers/get_freelancer_profile_professional_details`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};



setFormData = (jsonData:any) => {
  const {
    attributes: {
      work_experiences,
      educations,
      certificates,
      licenses,
    },
  } = jsonData.data;

  const licenseForms = licenses.map((license:any) => {
    if (license) {
      return {
        id: license.id ? license.id.toString() : '',
        Licensed_Profession: license.licensed_profession,
        Licensing_Body: license.jurisdiction_or_licensing_body,
        License_Number: license.license_number,
        Licensing_Verification_URL: license.licensing_verification_url,
        Issue_Date: license.issue_date,
        Expiration_Date: license.expiry_date,
      };
    } else {
      return null;
    }
  }).filter(Boolean);

  const certificateForms = certificates.map((certificate:any) => {
    if (certificate) {
      return {
        id: certificate.id ? certificate.id.toString() : '',
        certificate_name: certificate.certificate_name,
        certificate_provider: certificate.certificate_provider,
        certificate_id: certificate.certification_id,
        certificate_url: certificate.certification_url,
        certificate_issue: certificate.issue_date,
        certificate_expire_date: certificate.expiration_date,
      };
    } else {
      return null;
    }
  }).filter(Boolean);

  const educationForms = educations.map((education:any) => {
    if (education) {
      return {
        id: education.id ? education.id.toString() : '',
        degree: education.degree_name,
        university: education.college_name,
        country: education.country, 
        yearFrom: education.start_date ? education.start_date.split('-')[0] : '',
        yearTo: education.end_date ? education.end_date.split('-')[0] : '',
        description: education.description,
      };
    } else {
      return null;
    }
  }).filter(Boolean);

  const experienceForm = work_experiences.map((experience:any) => {
    if (experience) {
      return {
        id: experience.id ? experience.id.toString() : '',
        title: experience.job_title,
        company: experience.company,
        startMonth: experience.start_date ? experience.start_date.split('-')[1] : '',
        startYear: experience.start_date ? experience.start_date.split('-')[0] : '',
        endMonth: experience.end_date ? experience.end_date.split('-')[1] : '',
        endYear: experience.end_date ? experience.end_date.split('-')[0] : '',
        description: experience.description,
        isCurrentlyWorking: false, 
      };
    } else {
      return null;
    }
  }).filter(Boolean);

  this.setState({
    licenseForms,
    certificateForms,
    educationForms,
    experienceForm,
  });
}

doItLater(){
  this.setState({do_It_Later:true})
}

  // Customizable Area End
}