import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export const baseURLconfig = require("../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email:string
  isDialogOpen: boolean,
  loading:boolean;
  verfication: boolean;
  successPage: boolean;
  VerificationError: boolean
  otpError:string;
  otpValue: any;
  seconds:number;
  disabledResendOtp: boolean,
  timerExpired: boolean,
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OTPVerificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email:"",
      isDialogOpen: false,
      loading:false,
      verfication: false,
      successPage: false,
      VerificationError: false,
      otpError:"",
      otpValue: "",
      seconds:60,
      disabledResendOtp: true,
      timerExpired: true,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }

  // Customizable Area Start
  handleOtpValue = (otpPin: any) => {
    if (otpPin && otpPin.length === 6) {
      this.setState({ otpValue: otpPin, VerificationError: false, otpError: "" });
    } else {
      this.setState({ VerificationError: true });
    }
  }
  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };
    handleHome = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  handleVerifybox = () => {
    if (this.state.otpValue === "") {
      this.setState({ VerificationError: true })
    } 
  }
  handleResendOtp = () => {
    this.setState({ disabledResendOtp: true, timerExpired: false });
    const countdown = setInterval(() => {
      const { seconds } = this.state;

      if (seconds === 0) {
        clearInterval(countdown);
        this.setState({ disabledResendOtp: false, seconds: 60 });
      } else {
        this.setState({ seconds: seconds - 1 });
      }
    }, 1000); 
  };
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  // Customizable Area End
}
