import React from "react";
// Customizable Area Start

import OTPVerificationController, {
    Props
  } from "./OTPVerificationController";
import { Grid, Typography, Box, FormControl, styled, Dialog, IconButton, DialogContent } from "@material-ui/core";
import { Horizontalinventohub } from "../../landingpage/src/assets";
import ReactCodeInput from "react-code-input";
import CloseIcon from '@material-ui/icons/Close';
import { image_Success, login } from "./assets";
const CustomDialog = styled(Dialog)({
    '& .MuiPaper-rounded':{
      borderRadius: "18px",
      width:"480px"
    }
  })
  const MainContainer = styled(Box)({
    paddingInline: 20,
    '@media (max-width: 959px)': {
      paddingInline: 15,
      '& .dots-img': {
        display: 'none',
      },
    },
    '@media (max-width: 600px)': {
      paddingInline: 10,
    },
  });
  
  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      height:"64px",
      width: '64px',
      margin: '4px',
      MozAppearance: 'textfield' as "textfield",
      border: '1px solid gray',
      fontSize: '30px',
      backgroundColor: 'white',
      color: 'gray',
      textAlign: "center" as "center",
      borderRadius: '10px',
    },
    inputStyleInvalid: {
      borderRadius: '3px',
      MozAppearance: 'textfield' as "textfield",
      width: '40px',
      height: '26px',
      fontFamily: 'Rubik',
      fontSize: '14px',
      paddingLeft: '7px',
      border: '1px solid red',
      backgroundColor: 'black',
      color: 'red',
      margin: '4px',
    }
  }
  
  const props2 = {
    inputStyle: {
      height: '45px',
      margin: '4px',
      MozAppearance: 'textfield' as "textfield",
      backgroundColor: 'white',
      color: 'gray',
      fontFamily: 'Rubik',
      textAlign: "center" as "center",
      borderRadius: '10px',
      fontSize: '30px',
      width: '45px',
      border: '1px solid gray',
    },
    inputStyleInvalid: {
      margin: '4px',
      width: '40px',
      fontSize: '14px',
      backgroundColor: 'black',
      fontFamily: 'monospace',
      borderRadius: '3px',
      MozAppearance: 'textfield' as "textfield",
      height: '26px',
      border: '1px solid red',
      paddingLeft: '7px',
      color: 'red',
    }
  }

// Customizable Area End
export default class OTPVerification extends OTPVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
// Customizable Area Start
  congratsDialogBox = () => {
    return (
        <CustomDialog
        aria-labelledby="alert-dialog-title"
        open={this.state.isDialogOpen}
        aria-describedby="alert-dialog-description"
        onClose={this.handleCloseDialog}
        >
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        onClick={this.handleCloseDialog}
                        style={{ position: 'absolute', right: 22, top: 12 }}
                        edge="end"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            <DialogContent style={{ padding:"24px",textAlign: 'center', }}>
                <img src={image_Success} alt="Success" style={{borderRadius: "50%", width: "35%", marginBottom:"10px",marginTop:"20px" ,}} />
                <Typography gutterBottom style={webStyle.congratsText}>
                    Congratulations!
                </Typography>
                <Typography style={webStyle.profileCreatedText}>
                Your account has been created successfully.
                </Typography>
                <Box style={{ flexDirection: "column",display: "flex" }}>
                    <button
                        onClick={this.handleHome}
                        style={{ borderRadius:"8px",marginBottom: '10px',background:"#364BA0",color: "white" }}
                    >
                        COMPLETE MY PROFILE
                    </button>
                    <button
                        onClick={this.handleHome}
                        style={{borderRadius:"8px",color:"#4A4A4A",borderColor:"#4A4A4A0" }}
                    >
                        REMIND ME LATER
                    </button>
                </Box>
            </DialogContent>
        </CustomDialog>
    );
};
displayTime=()=> {
    const { seconds } = this.state;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }
  // Customizable Area End
  render() {
     // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      <MainContainer>
        <Grid container spacing={0}>
          <Grid container item xs={12} md={5} justifyContent="center">
            <Grid item xs={11} sm={9}>
            <img  onClick={this.handleLogoClick} style={webStyle.invento}src={Horizontalinventohub} data-test-id="handleLogoClick"/>
            <div style={webStyle.verifyStyle}>
              <Typography style={webStyle.otpStyle}>OTP Verification</Typography>
              <Typography style={webStyle.otpTime}>Enter 6 Digit verification code sent to your email ID  - {this.state.email}</Typography>
              <div>
              <ReactCodeInputStyle
                type="number"
                onChange={this.handleOtpValue}
                fields={6}
                name={"VerifyOtp"}
                {...props}
                inputMode={"tel"}
                value={this.state.otpValue}
                />
                  <ReactCodeInputStyle2
                {...props2}
                type="number"
                inputMode={"tel"} 
                fields={6}
                name={"VerifyOtp"} 
                value={this.state.otpValue} 
                onChange={this.handleOtpValue}
                />
                </div>
                
                <div style={{display:"flex",gap:"108px",marginTop:"24px"}}> 
              <Typography style={webStyle.otpTime}>Enter Verification Code:{this.displayTime()}</Typography>
              <span style={webStyle.ResendStyle} onClick={this.handleResendOtp} 
                data-testid="resend_emailotp">Resend Code</span>
                </div>

              <Box component={"div"} style={webStyle.topSpacing}>
                <FormControl style={{ maxWidth: 432,margin:"auto",width:"100%" }}>
                  <button
                    style={webStyle.nxtBtn}
                    onClick={this.handleVerifybox}
                    disabled={this.state.VerificationError}
                  >
                    VERIFY
                  </button>
                </FormControl>
              </Box>
              {this.state.otpError && <Typography style={webStyle.timer}>{this.state.otpError}</Typography>}
              <Box margin={"30px 0"} component={"div"}>
                <span style={webStyle.haveAccount}>Have an account? </span>
                <span style={webStyle.signIn} onClick={this.handleSignIn}>SIGN IN</span>
              </Box>
            </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7} className="dots-img">
            <img src={login} style={webStyle.image} alt="Login" />
          </Grid>
        </Grid>
        </MainContainer>
      {this.state.isDialogOpen && <>
    {this.congratsDialogBox()}
  </>
  }
      </>
    // Customizable Area End
    );
  }
}

// Customizable Area Start
const ReactCodeInputStyle=styled(ReactCodeInput)({
    "@media (max-width: 1305px)": {
  display:"none !important" 
    }
  })
  const ReactCodeInputStyle2=styled(ReactCodeInput)({
    display:"none !important" ,
    "@media (max-width: 1305px)": {
    display:"inline-block !important" 
  
    }
  })

const webStyle = {
    congratsText:{
  marginTop:"10px",
  fontSize: "22px",
  color: "#059669",
  fontWeight: 600,
  fontFamily: "Rubik"
},
profileCreatedText:
{
   marginBottom: '20px',
   color:"#011342",
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "18px",
  fontFamily: "Rubik"
},
    starStyle:{
        fontWeight: 800,
        color: "#FF5E5B",
    },
      invento:{
        width:"264px",
        height:"48px",
        objectFit:"cover" as "cover",
         marginTop: "42px",
         marginLeft:"10px",
         marginBottom: "34px",
         cursor:"pointer"
      },
      verifyStyle: {
        display: 'flex',
        flexDirection: 'column' as 'column',
      },
      otpStyle: {
        marginTop: "80px",
        fontSize: "32px",
        fontWeight: 700,
        color: "#364BA0",
        fontFamily: "Rubik"
      },
      otpTime: {
        marginBottom: "20px",
        color: "#535353",
        fontWeight: 500,
        fontSize: "16px",
        fontFamily: "Rubik",
      },
      ResendStyle:{
        color: "#364BA0",
        fontFamily: "Rubik",
        fontSize: "14px",
        fontWeight: 500,
        textDecoration: "underline",
        cursor: "pointer",

      },
      topSpacing: {
        marginTop: "16px"
      },
      nxtBtn: {
        padding: "16px", 
        borderRadius: "8px",
        marginBottom: '10px',
        backgroundColor: "#364BA0",
        color: 'white',
        border:"1px solid #364BA0",
        fontWeight: "bold" as "bold",
      },
      timer: {
        fontSize: "18px",
        fontFamily: "Inter",
        color: "red",
        marginTop: "10px"
      },
      haveAccount: {
        color: "#6F6E6E",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 500,
        lineHeight: "40px"
      },
      signIn: {
        color: "#364BA0",
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "40px",
        textDecoration: "underline",
        cursor: "pointer"
      },
      image: {
        width: "50%",
        height: "auto",
        position:"absolute" as "absolute",
        right:0,
        "@media (max-width: 768px)": {
          display: "none"
        }
      },
}
// Customizable Area End