import { Box, Card, Grid, Typography } from '@material-ui/core'
import { styled } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'

export function AppHeader() {
  return <View style={styles.container}>
      <Text style={styles.text}>Welcome to React Native Web + Monorepo</Text>
    </View>
}
export const importantField = () => {
  return <span style={{
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
      color: "#FF5E5B",
      display:"flex",
      justifyContent:"flex-start",
      alignItems:"flex-start"
  }}>
      {"  "}    *
  </span>
}
const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    backgroundColor: '#f3f3f3',
  },
  text: {
   fontSize: 36,
   fontWeight: '600',
  }
})
interface myProps {
  loading: boolean;
}
export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        <CircularProgress />
      </div>
    </div>
  ) : (
    <div />
  );
}
const useStyles = makeStyles(() => ({
  root: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1,
  },
  root2: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    zIndex: 1,
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

export const FirstContainerTypography = styled(Typography)({
  color: '#206FC4',
  fontWeight: 700,
  fontSize: '48px',
  marginBottom:"7px",
  '& p': {
    margin:"10px",
    
  },
  '& br': {
    display:"none",
  },
  '@media (max-width: 1702px)': {
          fontSize: '38px',
  },
  '@media (max-width: 1346px)': {
      fontSize: '36px',
  },
  '@media (max-width: 1254px)': {
      fontSize: '33px',
  },
  '@media (max-width: 1152px)': {
      fontSize: '31px',
  },
  '@media (max-width: 1040px)': {
      fontSize: '28px',
  },
  '@media (max-width: 970px)': {
      fontSize: '25px',
  },
  '@media (max-width: 831px)': {
      fontSize: '23px',
  },
  '@media (max-width: 759px)': {
      fontSize: '20px',
  },
  '@media (max-width: 661px)': {
      fontSize: '18px',
  },
})
export const FirstContainerTypographyTwo = styled(Typography)({
  color: '#002345',
      fontSize: "28px",
      fontFamily: 'Inter,Rubik',
      fontWeight: 400,
      '& p': {
        margin:"10px",
      },
      '& br': {
        display:"none",
      },
  '@media (max-width: 1368px)': {
      fontSize: "28px",
  },
  '@media (max-width: 1292px)': {
      fontSize: "26px",
  },
  '@media (max-width: 1254px)': {
      fontSize: "24px",
  },
  '@media (max-width: 1152px)': {
      fontSize: "22px",
  },
  '@media (max-width: 1040px)': {
      fontSize: "20px",
  },
  '@media (max-width: 970px)': {
      fontSize: "18px",
  },
  '@media (max-width: 939px)': {
      fontSize: "16px",
  },
  '@media (max-width: 759px)': {
      fontSize: "13px",
  },
})

export const SecondContainerTypography = styled(Typography)({
  fontSize: '49px',
  color: '#002345',
  fontWeight: 600,
  fontFamily: 'Inter,Rubik',
  letterSpacing: "0px",
  textAlign: "center" as "center",
  width: "100%",
  maxWidth: 1250,
  margin: 'auto',
  '& p': {
    margin: "0px",
  },
  '& br': {
    display: "none",
  },
  '@media(max-width: 1260px)': {
    fontSize: 32
  },
  '@media(max-width: 960px)': {
    fontSize: 28
  },
  '@media(max-width: 600px)': {
    fontSize: 24
  },
  '@media(max-width: 480px)': {
    fontSize: 18
  },
});

export const SecondContainerBox = styled(Box)(({ theme }) => ({
  paddingTop: 150,
  textAlign: 'center' as 'center',
  '@media(max-width: 960px)':{
    '& .dots-img': {
      display: 'none'
    },
    '& .unite-box': {
      gap: 15
    }
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 100, // Adjust padding top for smaller screens
  },
  [theme.breakpoints.down('xs')]: {
    paddingTop: 50,
    paddingInline: 12
  },
}));
export const CustomCardContainer = styled(Card)(({ theme }) => ({
  width: 'calc(33.3333% - 20px)', // 3 cards per row by default
  margin: '20px', // Add margin to the card container
  boxShadow: "0px 5px 14px 0px #1845A840",
  borderRadius: "15px",
  maxWidth: "312px",
  padding: "50px 36px 63px",
  zIndex: 1,
  [theme.breakpoints.down('lg')]: {
    width: 'calc(33.3333% - 20px)', // Adjust for larger screens
  },
  [theme.breakpoints.down('md')]: {
    width: 'calc(50% - 20px)', // 2 cards per row for medium screens
    padding: "40px 10px 50px", // Adjust padding for medium screens
    marginInline: '5px'
  },
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100% - 20px)', // 1 card per row for small screens
    padding: "30px 10px 40px", // Adjust padding for smaller screens
    margin: '10px', // Adjust margin for smaller screens
    maxWidth: 'unset'
  },
  [theme.breakpoints.down('xs')]: {
    padding: "0px",
    margin: '0px',
    width: '100%',
    '& .MuiCardContent-root': {
      padding: '12px !important'
    },
    '& .cardContent': {
      fontSize: '16px !important'
    },
    '& .cardHeading': {
      fontSize: '24px !important'
    },
  },
}));

export const ThirdContainerTypography = styled(Typography)({
  fontSize: '50px',
  color: '#002345',
  fontWeight: 600,
  fontFamily: 'Inter,Rubik',
  letterSpacing: "0px",
  textAlign: "center" as "center",
  width: "100%",
 ' @media (max-width: 1200px)': {
    fontSize: "40px"
  },

 ' @media (max-width: 768px)': {
    fontSize: "30px"
  },

  '@media (max-width: 600px)': {
    fontSize: "24px"
  },

  '@media (max-width: 480px)': {
    fontSize: "18px"
  },

  '@media (max- width: 360px)': {
    fontSize: "16px"
  }
})
export const ThirdContainerBox = styled(Box)({
      padding: '12px',
      textAlign: 'center' as 'center',
      '@media (max-width: 480px)': {
        '& .cardcontent': {
          fontSize: '16px !important'
        }
      },
})
export const CustomThirdSectionGrid = styled(Grid)({
  maxWidth: 1650,
  margin: 'auto',
  width: '100%',
  '& .explore-box': {
    gap: 15,
    justifyContent: 'center',
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column',
  },
  '@media (min-width: 960px)': {
    '& .explore-box': {
      gap: 15,
      '& .MuiGrid-grid-md-3': {
        maxWidth: '23%'
      }
    },
  },
})

export const FourthContainerTypography =styled(Typography)({
  fontSize: '44px',
  color: '#fff',
  fontWeight: 600,
  maxWidth:"818px",
  maxHeight:"818px",
  '& p': {
    margin:"0px",
  },
  '& h2': {
    margin:"0px",
  },
  '& br': {
    display:"none",
  },
      '@media (max-width: 1540px)': {
          fontSize: '42px',
    
      },
      '@media (max-width: 1481px)': {
        fontSize: '40px',
  
    },
      '@media (max-width: 1469px)': {
          fontSize: '40px',
    
      },
      '@media (max-width: 1399px)': {
          fontSize: '38px',
    
      },
      '@media (max-width: 1329px)': {
          fontSize: '36px',
    
      },
      '@media (max-width: 1259px)': {
          fontSize: '34px',
    
      },
      '@media (max-width: 1189px)': {
          fontSize: '32px',
    
      },
      '@media (max-width: 1119px)': {
          fontSize: '30px',
    
      },
      '@media (max-width: 1049px)': {
          fontSize: '28px',
    
      },
      '@media (max-width: 899px)': {
          fontSize: '26px',
    
      },
      '@media (max-width: 854px)': {
          fontSize: '21px',
    
      },
      '@media (max-width: 725px)': {
          fontSize: '19px',
    
      },
})
export const FourthContainerGrid = styled(Grid)({
  maxWidth: 1650,
    margin: 'auto',
    width: '100%',
    marginTop: 150,
    marginLeft: 50,
    marginRight: 50,
  '& .unlock-parent-box': {
    gap: 140
  },
  '@media (max-width: 1260px)': {
    marginTop: 50,
    marginLeft: 30,
    marginRight: 30,
    paddingInline: 12,
      '& .unlock-parent-box': {
        gap: 50,
        flexDirection: 'column'
      }
  },
  '@media (max-width: 979px)': {
    marginTop: 50,
    marginLeft: 30,
    marginRight: 30,
    paddingInline: 12,
      '& .unlock-parent-box': {
        gap: 50,
        flexDirection: 'column'
      }
  },
  '@media (max-width: 600px)': {
    marginLeft: 6,
    marginRight: 6,
    paddingInline: 12,
      '& .unlock-parent-box': {
        gap: 50,
        flexDirection: 'column'
      }
  },
})
export const FourthContainerTypographyTwo =styled(Typography)({
  fontSize: '20px',
  maxWidth:"495px",
  maxheight:"72px",
  color: '#FFFFFF',
  '& p': {
    color: '#FFFFFF',
  },
  '@media (max-width: 1032px)': {
      fontSize: '18px',
     
  },
  '@media (max-width: 854px)': {
      fontSize: '16px',
     
  },
  '@media (max-width: 725px)': {
      fontSize: '14px',
     
  },
   
})
export const FiveContainerTypography = styled(Typography)({
  fontSize: '48px',
  fontWeight: 600,
  color: '#002345',
  margin: '15px 0',
  '& p': {
    margin:"0px",
  },
  '& br':{
    display:"none"
  },
  '@media (max-width: 1428px)': {
      fontSize: '46px',
     
  },
  '@media (max-width: 1340px)': {
      fontSize: '44px',
     
  },
  '@media (max-width: 1282px)': {
      fontSize: '42px',
     
  },
  '@media (max-width: 1242px)': {
      fontSize: '40px',
     
  },
  '@media (max-width: 1130px)': {
      fontSize: '36px',
     
  },
  '@media (max-width: 1065px)': {
      fontSize: '34px',
     
  },
  '@media (max-width: 990px)': {
      fontSize: '30px',
     
  },
  '@media (max-width: 845px)': {
      fontSize: '28px',
     
  },
  '@media (max-width: 800px)': {
      fontSize: '26px',
     
  },
  '@media (max-width: 700px)': {
      fontSize: '22px',
     
  },

})
export const FiveContainerImageTypography = styled(Typography)({
  color: "#fff",
  fontWeight: 500,
  fontSize: "32px",
  '@media (max-width: 1670px)': {
  fontSize: "30px",
  },
  '@media (max-width: 1575px)': {
  fontSize: "26px"
     
  },
  '@media (max-width: 1425px)': {
  fontSize: "20px"
     
  },
  '@media (max-width: 1141px)': {
  fontSize: "18px"
     
  },

})
export const FiveContainerImageTypography2 = styled(Typography)({
  color: "#fff",
  fontWeight: 500,
  fontSize: "31px",
  '@media (max-width: 1670px)': {
  fontSize: "27px",
  },
  '@media (max-width: 1575px)': {
    fontSize: "24px"
    
  },
  '@media (max-width: 1520px)': {
    fontSize: "20px"
       
    },
  '@media (max-width: 1439px)': {
  fontSize: "18px"
     
  },
  '@media (max-width: 1141px)': {
  fontSize: "16px"
     
  },

})
export const FiveContainerTypographyTwo = styled(Typography)({
  color: '#002345',
      fontSize: '17px',
      '@media (max-width: 1501px)': {
          fontSize: '16px',
      },
  '@media (max-width: 1428px)': {
      fontSize: '16px',
  },
  '@media (max-width: 1412px)': {
      fontSize: '15px',
  },
  '@media (max-width: 1340px)': {
      fontSize: '14px',
  },
  '@media (max-width: 1242px)': {
      fontSize: '12px',
  },
  '@media (max-width: 1058px)': {
      fontSize: '10px',
      
  },
  '@media (max-width: 882px)': {
      fontSize: '8px',
  },
  '@media (max-width: 706px)': {
      fontSize: '7px',
  },

})


export const SixContainerTypography = styled(Typography)({
fontSize: '48px',
      fontWeight: 600,
      color: '#002345',
      '& p': {
        margin:"0px",
      },
      '& h2': {
        margin:"0px",
      },
      '& br':{
        display:"none"
      },
       '@media (max-width: 1496px)': {
      fontSize:"45px"
  },
  '@media (max-width: 1401px)': {
      fontSize:"42px"
  },
  '@media (max-width: 1308px)': {
      fontSize:"40px"
  },
  '@media (max-width: 1245px)': {
      fontSize:"38px"
  },
  '@media (max-width: 1183px)': {
      fontSize:"36px"
  },
  '@media (max-width: 1119px)': {
      fontSize:"28px"
  },
  '@media (max-width: 872px)': {
    fontSize:"26px"
},
'@media (max-width: 809px)': {
  fontSize:"22px"
},
'@media (max-width: 685px)': {
  fontSize:"20px"
},

})

export const SixContainerTypographyTwo = styled(Typography)({
  color: '#002345',
  fontSize: '17px',
  '@media (max-width: 778px)': {
    fontSize:"15px"
  },
  '@media (max-width: 685px)': {
    fontSize:"10px"
  },
  
  })


  export const SevenContainerTypography = styled(Typography)({
    fontSize: '48px',
    fontWeight: 600,
    color: '#002345',
    margin: '15px 0',
    '& p': {
      margin: "0px",
    },
    '& h2': {
      margin: "0px",
    },
    '& br': {
      display: "none"
    },
    '@media (max-width: 1286px)': {
      fontSize: '46px',
    },
    '@media (max-width: 1128px)': {
      fontSize: '44px',
    },
    '@media (max-width: 959px)': {
      fontSize: '42px',
      display: "flex",
      justifyContent: "center"
    },
    '@media (max-width: 970px)': {
      fontSize: '40px',
    },
    '@media (max-width: 841px)': {
      fontSize: '38px',
    },
    '@media (max-width: 835px)': {
      fontSize: '34px',
    },
    '@media (max-width: 600px)': {
      fontSize: '30px',
    },
  });

  export const SevenContainerTypographyTwo = styled(Typography)({
    color: '#002345',
    fontSize: '17px',
    '& p': {
      margin: "0px",
    },
    '& h2': {
      margin: "0px",
    },
    '& br': {
      display: "none"
    },
    '@media (max-width : 1443px)': {
      fontSize: "15px"
    },
    '@media (max-width: 1286px)': {
      fontSize: "13px"
    },
    '@media (max-width: 1128px)': {
      fontSize: '11px',
    },
    '@media (max-width: 959px)': {
      fontSize: '15px',
      textAlign: "center"
    },
    '@media (max-width: 600px)': {
      fontSize: '14px',
    },
  });

      export const SevenContainerTypographyGrid = styled(Grid)({
        maxWidth: 1650,
        margin: 'auto',
        width: '100%',
        marginTop: 150,
        marginRight: 50,
        marginLeft: 50,
        '@media (max-width: 1260px)': {
          marginTop: 50,
          padding: 12,
          marginRight: 30,
          marginLeft: 30,
          '& .video-card-div': {
          padding: '0px !important'
        }
      },
        '@media (max-width: 970px)': {
            marginTop: 50,
            padding: 12,
            marginRight: 30,
            marginLeft: 30,
            '& .video-card-div': {
            padding: '0px !important'
          }
        },
        '@media (max-width: 600px)': {
          marginTop: 50,
          padding: 12,
          marginRight: 6,
          marginLeft: 6,
          '& .video-card-div': {
          padding: '0px !important'
        }
      },
      });

      export const secondContainerImage = styled('img')({
        "@media (max-width: 1214)": {
          width: "75%"
        }
      });

      export const EightContainerTypography = styled(Typography)({
        fontSize: '44px',
        color: '#fff',
        fontWeight: 600,
        '& p': {
          margin: "0px",
        },
        '& h2': {
          margin: "0px",
        },
        '& br': {
          display: "none"
        },
        '@media (max-width: 1645px)': {
          fontSize: '42px',
        },
        '@media (max-width: 1552px)': {
          fontSize: '40px',
        },
        '@media (max-width: 1479px)': {
          fontSize: '38px',
        },
        '@media (max-width: 1390px)': {
          fontSize: '36px',
        },
        '@media (max-width: 1317px)': {
          fontSize: '32px',
        },
        '@media (max-width: 1171px)': {
          fontSize: '30px',
        },
        '@media (max-width: 1097px)': {
          fontSize: '27px',
        },
        '@media (max-width: 987px)': {
          fontSize: '25px',
        },
        '@media (max-width: 959px)': {
          fontSize: '44px',
          textAlign: "center"
        },
        '@media (max-width: 800px)': {
          fontSize: '40px',
        },
        '@media (max-width: 700px)': {
          fontSize: '38px',
        },
        '@media (max-width: 634px)': {
          fontSize: '36px',
        },
        '@media (max-width: 601px)': {
          fontSize: '34px',
        },
        '@media (max-width: 500px)': {
          fontSize: '32px',
        },
        '@media (max-width: 400px)': {
          fontSize: '30px',
        },
      })
      
      export const EightContainerTypographyTwo = styled(Typography)({
        fontSize: '20px',
        color: '#fff',
        '& p': {
          margin: "0px",
        },
        '& h2': {
          margin: "0px",
        },
        '& br': {
          display: "none"
        },
        '@media (max-width: 1645px)': {
          fontSize: '18px',
        },
        '@media (max-width: 1479px)': {
          fontSize: '16px',
        },
        '@media (max-width: 1307px)': {
          fontSize: '14px',
        },
        '@media (max-width: 1144px)': {
          fontSize: '12px',
        },
        '@media (max-width: 959px)': {
          fontSize: '18px',
          textAlign: "center"
        },
        '@media (max-width: 600px)': {
          fontSize: '14px',
        },
      })
      
      
      export const NineContainerTypography = styled(Typography)({
        fontSize: '45px',
        color: '#002345',
        fontWeight: 600,
        textAlign: 'center' as 'center',
        '@media (max-width: 600px)': {
          fontSize: '24px',
        },
        '& p': {
          margin:"0px",
        },
        '& h2': {
          margin:"0px",
        },
        '& br':{
          display:"none"
        },

      })

      export const NineContainerTypographyTwo = styled(Typography)({
      fontSize: '16px',
      color: '#002345',
      textAlign: 'center' as 'center',
      padding: "0 1% 4% 1%",
      '& p': {
        margin:"0px",
      },
      '& h2': {
        margin:"0px",
      },
      '& h3': {
        margin:"0px",
      },
      })
      export const CustomGrid = styled(Grid)({
        '@media (max-width: 959px)': {
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column'

        },
      })
     export const customStyles = {
        control: (provided: any) => ({
          ...provided,
          "&:hover": {
            borderColor: "none"
          },
          boxShadow: "none"
        }),
        option: (provided: any, state: any) => ({
          ...provided,
          color: state.isFocused ? "#000000" : "#333333",
          backgroundColor: state.isFocused ? "#ffffff" : "transparent", 
          margin: "auto",
          width: "95%",
          cursor:"pointer",
          "&:hover": {
            color: "#ffffff",
            backgroundColor: "#206FC4",
            borderRadius: 10,
          }
        }),
        dropdownIndicator: (provided: any) => ({
          ...provided,
          "&:hover": {
            color: "initial" 
          }
        })
      };
  