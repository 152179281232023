import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ChangeEvent } from "react";

// Customizable Area Start
import storage from 'framework/src/StorageProvider';
import { stringify } from "querystring";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
export interface ExpertiseAndFees {
  data: {
    id: string,
    type: string,
    attributes: {
      id: number,
      first_name: string,
      last_name: string,
      role_id: string,
      profile_status: string,
      area_of_expertise: string[],
      hour_rate: null,
      services_fees: number,
      received_fees: number,
      industries: [
        {
          id: number,
          name: string
        }
      ],
      services: [
        {
          id: number,
          name: string
        }
      ],
      country_expertises: [
        {
          id: number,
          account_id: number,
          country_name: string,
          cities: [
            string,
            string
          ],
        }
      ],
      language_expertises: [
        {
          id: number,
          name: string,
          proficiency: string,
        },
      ]
    }
  }
}

export interface Service {
  data: [
    {
      id: number,
      percentage: string,
    }
  ]
}
// Customizable Area End

interface S {
  // Customizable Area Start
  expertiseArea: string
  countryExpertise: string
  language: string
  proficiency: string
  expertiseSkills: string[]
  industryFocus: string
  industryExpertiseArray: any[]
  selectedIndustries: string[]
  services: string
  servicesArray: any[]
  selectedCountries: string[]
  selectedServices: string[]
  expertiseAreaError: string
  countryExpertiseError: string
  industryFocusError: string
  languageError: string
  servicesError: string
  proficiencyError: any[]
  addLanguage: any[]
  authToken: string
  userID: number | null;
  countryList: {
    id: number,
    name: string,
    code: string,
  }[],
  expertiseAndFees: ExpertiseAndFees
  projectRate: string,
  servicesFee: Service,
  estimatedAmount: string,
  backdata:boolean,
  Countries_id:string,
  Language_id:string,
  do_It_Later:boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class ExpertiseAndFeesFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiExpertiseFeesID: string = "";
  allCountriesID: string = "";
  serviceFeesID: string = "";
  serviceID: string = "";
  industryID: string = "";
  handleformexpertise_backAPIid:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      do_It_Later:false,
      expertiseArea: '',
      countryExpertise: "",
      proficiency: "",
      industryFocus: "",
      language: "",
      services: "",
      expertiseSkills: [],
      industryExpertiseArray: [],
      selectedIndustries: [],
      servicesArray: [],
      selectedCountries: [],
      selectedServices: [],
      expertiseAreaError: "",
      countryExpertiseError: "",
      industryFocusError: "",
      languageError: "",
      servicesError: "",
      proficiencyError: [],
      addLanguage: [{
        language: "",
        proficiency: ""
      }],
      authToken: "",
      userID: null,
      countryList: [],
      expertiseAndFees: {
        "data": {
          "id": "1",
          "type": "freelancer_profile_expertise_and_fees",
          "attributes": {
            "id": 1,
            "first_name": "Nitin",
            "last_name": "Ji",
            "role_id": "freelancer",
            "profile_status": "completed",
            "area_of_expertise": [
              "IP Licensing",
              "Industrial Design",
              "IP Valuation"
            ],
            "hour_rate": null,
            "services_fees": 5.0,
            "received_fees": 45.0,
            "industries": [
              {
                "id": 1,
                "name": "Industry Data"
              }
            ],
            "services": [
              {
                "id": 1,
                "name": "TTTTTTT"
              }
            ],
            "country_expertises": [
              {
                "id": 21,
                "account_id": 1,
                "country_name": "India",
                "cities": [
                  "Delhi",
                  "Mumbai"
                ],
              },
            ],
            "language_expertises": [
              {
                "id": 1,
                "name": "Tamil",
                "proficiency": "Expert",
              }
            ]
          }
        }
      },
      projectRate: "",
      servicesFee: {
        data: [
          {
            id: 0,
            percentage: "",
          }
        ]
      },
      estimatedAmount: "",
      backdata:false,
      Countries_id:"",
      Language_id:"",
      


      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));
      
      if (sessionData.backdata) {
        this.setState({ backdata:sessionData.backdata});
        this.handleExpertisebackdata()
      }
      return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (webResponseJson) {
        if (webApiRequestCallId === this.apiExpertiseFeesID) {
          this.setState({ expertiseAndFees: webResponseJson });
        }
        if (webApiRequestCallId === this.allCountriesID) {
            const countries = webResponseJson.map((country: any) => ({
              id: country.id,
              name: country.name,
              code: country.code,
            }));
            this.setState({ countryList: countries });
        }
        if (webApiRequestCallId === this.serviceFeesID) {
            const serviceFees = webResponseJson.data[0].percentage;
            this.setState({ servicesFee: { data: [{ id: 0, percentage: serviceFees }] } });
        }

        if (webApiRequestCallId === this.serviceID) {
          if (webResponseJson && webResponseJson.data) {
            const services = webResponseJson.data.map((service: any) => ({
              id: service.id,
              name: service.name
            }));
            this.setState({ servicesArray: services });
          }
        }

        if (webApiRequestCallId === this.industryID) {
          if (webResponseJson && webResponseJson.data) {
            const industries = webResponseJson.data.map((industry: any) => ({
              id: industry.id,
              name: industry.name
            }));
            this.setState({ industryExpertiseArray: industries });
          }
        }
        if (webApiRequestCallId === this.handleformexpertise_backAPIid) {
          console.log("aaaa==== res rece",webResponseJson);
          const extractedData = this.extractDataFromJson(webResponseJson);
          this.setState({
            expertiseSkills: extractedData.expertiseSkills,
            selectedIndustries: extractedData.selectedIndustries,
            industryExpertiseArray: extractedData.industryExpertiseArray,
            selectedServices: extractedData.selectedServices,
            servicesArray: extractedData.servicesArray,
            selectedCountries: extractedData.selectedCountries,
            // countryExpertises: extractedData.countryExpertises,
            addLanguage: extractedData.addLanguage,
            projectRate: extractedData.projectRate,
            // servicesFee: extractedData.servicesFee,
            estimatedAmount: extractedData.estimatedAmount,
            // userID: jsonData.data.id
          });

          
          // this.setStateFromJson(webResponseJson)
          
       }

      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.setState({
      userID: await storage.get('accountId'),
      authToken: await storage.get('authToken'),
    });
    this.getAllCountriesListApi()
    this.getServiceFees()
    this.getService()
    this.getIndustry()
  }
  validateForm = () => {
    const {
      expertiseSkills,
      countryList,
      industryExpertiseArray,
      servicesArray,
    } = this.state;

    let isValid = true;

    if (expertiseSkills.length === 0) {
      this.setState({ expertiseAreaError: "* Please add your Area of Expertise" });
      isValid = false;
    } else {
      this.setState({ expertiseAreaError: "" });
    }

    if (countryList.length === 0) {
      this.setState({ countryExpertiseError: "* Please select your Country Expertise" });
      isValid = false;
    } else {
      this.setState({ countryExpertiseError: "" });
    }

    if (industryExpertiseArray.length === 0) {
      this.setState({ industryFocusError: "* Please add your Industry Focus" });
      isValid = false;
    } else {
      this.setState({ industryFocusError: "" });
    }

    if (servicesArray.length === 0) {
      this.setState({ servicesError: "* Please add your Services" });
      isValid = false;
    } else {
      this.setState({ servicesError: "" });
    }

    return isValid;
  };

  handleNext = () => {
    if (this.validateForm()) {
      this.setState({
        expertiseAreaError: "",
        countryExpertiseError: "",
        industryFocusError: "",
        languageError: "",
      });

    }
    this.handleExpertiseFeesApi()
    this.props.navigation.navigate('PatentsForm');
  };
  handleback(){
    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'CustomFormExperience'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {  backdata: true })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);

  }
  handleExpertiseInputChange = (event: { target: { value: any; } }) => {
    this.setState({ expertiseArea: event.target.value, expertiseAreaError: "" });
  };


  handleIndustrySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedIndustry = event.target.value as string;
    this.setState((prevState) => ({
      countryExpertiseError: "",
      selectedIndustries: prevState.selectedIndustries.includes(selectedIndustry)
        ? prevState.selectedIndustries
        : [...prevState.selectedIndustries, selectedIndustry],
    }));
  };

  handleServicesInputChange = (event: { target: { value: any; } }) => {
    const selectedService = event.target.value as string;
    this.setState((prevState) => ({
      services: '',
      servicesError: "",
      selectedServices: prevState.selectedServices.includes(selectedService)
        ? prevState.selectedServices
        : [...prevState.selectedServices, selectedService],
    }));
  };


  handleLanguageChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const addLanguage = [...this.state.addLanguage];
    addLanguage[index].language = value as string;

    const proficiencyError = [...this.state.proficiencyError];

    if (value && !addLanguage[index].proficiency) {
      proficiencyError[index] = true;
    } else {
      proficiencyError[index] = false;
    }

    this.setState({ addLanguage, proficiencyError });
  };

  handleProficiencyChange = (index: number, event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    const addLanguage = [...this.state.addLanguage];
    addLanguage[index].proficiency = value as string;

    const proficiencyError = [...this.state.proficiencyError];
    proficiencyError[index] = false;

    this.setState({ addLanguage, proficiencyError });
  };

  handleExpertiseKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && this.state.expertiseArea.trim() !== '') {
      this.setState((prevState) => ({
        expertiseSkills: [...prevState.expertiseSkills, prevState.expertiseArea.trim()],
        expertiseArea: '',
        expertiseAreaError: ""
      }));
    }
  };

  handleDeleteSkill = (skillToDelete: string) => {
    this.setState((prevState) => ({
      expertiseSkills: prevState.expertiseSkills.filter((skill) => skill !== skillToDelete),
    }));
  };

  handleDeleteIndustry = (industryToDelete: string) => {
    this.setState((prevState) => ({
      selectedIndustries: prevState.selectedIndustries.filter((industry) => industry !== industryToDelete),
    }));
  };

  handleDeleteService = (serviceToDelete: string) => {
    this.setState((prevState) => ({
      selectedServices: prevState.selectedServices.filter((service) => service !== serviceToDelete),
    }));
  };

  handleCountrySelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCountry = event.target.value as string;
    this.setState((prevState) => ({
      countryExpertiseError: "",
      selectedCountries: prevState.selectedCountries.includes(selectedCountry)
        ? prevState.selectedCountries
        : [...prevState.selectedCountries, selectedCountry],
    }));
  };

  handleDeleteCountry = (countryToDelete: string) => {
    this.setState((prevState) => ({
      selectedCountries: prevState.selectedCountries.filter((country) => country !== countryToDelete),
    }));
  };

  handleAddLanguage = () => {
    const newLanguage = {
      language: "",
      proficiency: "",
    };
    this.setState({
      addLanguage: [...this.state.addLanguage, newLanguage],
      proficiencyError: [...this.state.proficiencyError, false],
    });
  };

  handleRemoveLanguage = (index: number) => {
    const updatedLanguages = this.state.addLanguage.filter((_, i) => i !== index);
    const updatedProficiencyError = this.state.proficiencyError.filter((_, i) => i !== index);

    this.setState({
      addLanguage: updatedLanguages,
      proficiencyError: updatedProficiencyError,
    });
  };

  handleProjectRateChange = (event: { target: { value: any; }; }) => {
    const projectRate = event.target.value;
    // Ensure that serviceFee is a valid number, with a fallback to 0 if it's undefined
    const serviceFee = parseFloat(this.state.servicesFee?.data?.[0]?.percentage || '0');
    const estimatedAmount = parseFloat(projectRate) - serviceFee;

    this.setState({
      projectRate,
      estimatedAmount: isNaN(estimatedAmount) ? '0.00' : estimatedAmount.toFixed(2),
    });
  };



  handleEstimatedAmountChange = (event: { target: { value: any; }; }) => {
    this.setState({
      estimatedAmount: event.target.value,
    });
  };

  doItLater(){
    this.setState({do_It_Later:true})
  }

  handleExpertiseFeesApi = () => {
    const header = {
      redirect: 'follow',
      "token": this.state.authToken
    };

    let formdata = new FormData();

    // Append expertise skills
    this.state.expertiseSkills.forEach(skill => {
      formdata.append("account[area_of_expertise][]", skill);
    });


    // Append industry expertise
    this.state.selectedIndustries.forEach((industryName) => {
      const industryExpertise = this.state.industryExpertiseArray.find(industryExpertise => industryExpertise.name === industryName);
      if (industryExpertise) {
        formdata.append(`account[industry_ids][]`, industryExpertise.id);
      }
    });

    // Append services
    this.state.selectedServices.forEach((serviceName) => {
      const selectedService = this.state.servicesArray.find(selectedService => selectedService.name === serviceName);
      if (selectedService) {
        formdata.append(`account[service_ids][]`, selectedService.id);
      }
    });

    // Append country expertise    
    this.state.selectedCountries.forEach((country, index) => {
      if(this.state.backdata){
      formdata.append(` account[country_expertises_attributes][${index}][id]`, this.state.Countries_id);
     }
      formdata.append(`account[country_expertises_attributes][${index}][country_name]`, country);
    });

    // Append language expertise
    this.state.addLanguage.forEach((item, index) => {
      if(this.state.backdata){
      formdata.append(`account[language_expertises_attributes][${index}][id]`, this.state.Language_id);
      }
      formdata.append(`account[language_expertises_attributes][${index}][language_name]`, item.language);
      formdata.append(`account[language_expertises_attributes][${index}][proficiency]`, item.proficiency);
    });
    formdata.append(`account[hour_rate]`, this.state.projectRate);
    formdata.append(`account[services_fees]`, this.state.servicesFee.data[0].percentage);
    formdata.append(`account[received_fees]`, this.state.estimatedAmount);

    // Create the request message for API
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiExpertiseFeesID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/freelancers/${this.state.userID}/freelancer_profile_expertise_and_fees`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );

    // Send the message
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  getAllCountriesListApi = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.allCountriesID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/nations`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getServiceFees = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceFeesID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/get_service_fee`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getService = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.serviceID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/services`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getIndustry = () => {
    const header = {
      "Content-Type": "application/json",
      "token": this.state.authToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.industryID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/accounts/industries`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  handleExpertisebackdata = () => {
    const header = {
      "Content-Type": "application/json",
        token: this.state.authToken
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    this.handleformexpertise_backAPIid = requestMessage.messageId;
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/account_block/freelancers/get_freelancer_profile_expertise_and_fees`
    );
  
    requestMessage.addData( getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData( getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  extractDataFromJson = (jsonData:any) => {
    const data = jsonData.data.attributes;
  
    const expertiseSkills = data.area_of_expertise || [];
    const selectedIndustries = data.industries.map((industry: { name: any; }) => industry.name) || [];
    const industryExpertiseArray = data.industries || [];
    const selectedServices = data.services.map((service: { name: any; }) => service.name) || [];
    const servicesArray = data.services || [];
    const selectedCountries = data.country_expertises.map((country: { country_name: any; }) => country.country_name) || [];
    const Countries_id = data.country_expertises.map((country: { id: any; }) => country.id[0]) ;
    

    // Language_id:string,
    const countryExpertises = data.country_expertises || [];
    const addLanguage = data.language_expertises.map((language: { name: any; proficiency: any; }) => ({
      language: language.name,
      proficiency: language.proficiency
    })) || [];
    const Language_id = data.language_expertises.map((language: { id: any; }) => language.id[0]) ;


    const projectRate = data.hour_rate || 0;
    const servicesFee = { data: [{ percentage: data.services_fees }] };
    const estimatedAmount = data.received_fees || 0;
  
    return {
      expertiseSkills,
      selectedIndustries,
      industryExpertiseArray,
      selectedServices,
      servicesArray,
      selectedCountries,
      countryExpertises,
      addLanguage,
      projectRate,
      servicesFee,
      estimatedAmount,
      Countries_id,
      Language_id
    };
  };



  // Customizable Area End
}