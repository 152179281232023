import React from "react";
// ...


// Customizable Area Start
import {
  Typography,
  withStyles,
  Box,
  Button,
  styled,
  Dialog,
  DialogContent,
} from "@material-ui/core";

// Customizable Area End

import RemindMeDialogboxController, {
  Props,
  configJSON
} from "./RemindMeDialogboxController.web";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slider from '@material-ui/core/Slider';


import {
  image_Success,remind_icon
} from "./assets";
export  class RemindMeDialogbox extends RemindMeDialogboxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
 
  
  RemindMeLaterBtnDialogBox = () => {
    return (
      <CustomDialog
      open={this.state.isDialogOpen}
      onClose={this.handleCloseDialogBtn}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
        
      <DialogContent style={{ textAlign: 'center',padding:"24px" }}>
          <img src={remind_icon} alt="Success" style={{ width: "35%", borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
          
          <Typography style={webStyle.profileCreatedText}>
              Your profile has been created successfully.
          </Typography>
          <Box>
            <Box style={{display:"flex", justifyContent:"space-between"}}>
            <Typography style={webStyle.profileCreatedText_remind}>
            Profile Completed
          </Typography> 
            <Typography style={webStyle.profileCreatedText_remind}>
              70%
          </Typography>
            </Box>
          <PrettoSlider
                            valueLabelDisplay="auto"
                            aria-label="pretto slider"
                            defaultValue={30}
                            data-testid="linearprogress"
                            style={{ marginTop: '20px' }}
                          />
          </Box>
          <Box style={{ display: "flex", flexDirection: "column" }}>
              <Button
                  variant="contained"
                  color="primary"
                  style={{ marginBottom: '10px',borderRadius:"8px" }}
                  onClick={this.handleHomeBtn}
              >
                  COMPLETE MY PROFILE
              </Button>
              <Button
                  variant="outlined"
                  color="primary"
                  style={{borderRadius:"8px" }}
                  onClick={this.remindMeLaterBtn.bind(this)}
                  data-test-id="remindMeLaterBtn"
              >
                  REMIND ME LATER
              </Button>
          </Box>
      </DialogContent>
  </CustomDialog>
    )
  }
  
  // Customizable Area End

  render() {

    
    return (
        <>
     
    
          {this.RemindMeLaterBtnDialogBox()}
       
         
        </>
        //Customizable Area End
      );
  }
}

// Customizable Area Start
export const PrettoSlider = withStyles({
  root: {
    color: '#206FC4',
    height: 8,
    marginTop:0,
  },
  thumb: {
    display:"none",
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);
const CustomDialog = styled(Dialog)({
  '& .MuiPaper-rounded':{
    borderRadius: "18px",
    width:"480px"
  }
})
// Customizable Area End
const webStyle = {
  //Customizable Area Start
  congratsText:
  {
    marginTop:"10px",
    fontSize: "22px",
  color: "#059669",
  fontWeight: 600,
  fontFamily: "'Inter', sans-serif"
  },
  profileCreatedText:{
   marginBottom: '20px',
fontSize: "14px",
fontWeight: 500,
marginTop: "18px",
fontFamily: "'Inter', sans-serif"
},
profileCreatedText_remind:{
  marginBottom: '-7px',
  fontSize: "14px",
  fontWeight: 500,
  marginTop: "18px",
  fontFamily: "'Inter', sans-serif"

}
  //Customizable Area End
  
};
export default withStyles(webStyle)(RemindMeDialogbox);
// Customizable Area End